import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { H2 } from '../../../../components';

import css from './SectionFaq.module.css';
import background from './img/background.svg';
import iconMinus from './img/minus-icon.svg';
import iconPlus from './img/plus-icon.svg';

const questions = [
    {
        question: 'question1',
        answer: 'answer1',
    },
    {
        question: 'question2',
        answer: 'answer2',
    },
    {
        question: 'question3',
        answer: 'answer3',
    },
    {
        question: 'question4',
        answer: 'answer4',
    },
    {
        question: 'question5',
        answer: 'answer5',
    },
];

const SectionFaq = props => {
    const [activeItem, setActiveItem] = useState(0);

    const toggleItem = index => {
        setActiveItem(activeItem === index ? null : index);
    };

    return (
        <div className={css.root}>
            <img className={css.backgroundImage} src={background} />
            <span className={css.tag}>
                <FormattedMessage id="SectionFaq.tag" />
            </span>
            <H2 className={css.title}>
                <FormattedMessage
                    id="SectionFaq.titlePart1"
                />
                <br />
                <span className={css.customGradient}>
                    <FormattedMessage
                        id="SectionFaq.titlePart2"
                    />
                </span>
            </H2>

            <div className={css.container}>
                {questions.map((item, index) =>
                    <div
                        key={index}
                        className={`${css.item} ${activeItem === index ? css.active : ''}`}
                    >
                        <h3
                            className={css.question}
                            // className={`${activeItem === index ? css.questionExpanded : css.question}`}
                            onClick={() => toggleItem(index)}
                        // onTouchEnd={() => toggleItem(index)}
                        >
                            {/* <strong> */}
                            <FormattedMessage id={`SectionFaq.${item.question}`} />
                            {/* </strong> */}
                            {activeItem === index ?
                                <img src={iconMinus} /> :
                                <img src={iconPlus} />
                            }

                        </h3>
                        <p
                            className={css.answer}
                        >
                            <FormattedMessage id={`SectionFaq.${item.answer}`} />
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SectionFaq;