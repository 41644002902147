import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/ui.duck';

import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './LandingPage.module.css';

import SectionHero from './sections/SectionHero/SectionHero';
import SectionMatched from './sections/SectionMatched/SectionMatched';
import SectionSlider from './sections/SectionSlider/SectionSlider';
import SectionLocation from './sections/SectionLocation/SectionLocation';
import SectionFaq from './sections/SectionFaq/SectionFaq';
import SectionFooter from '../PageBuilder/SectionBuilder/SectionFooter';

export const LandingPageComponent = props => {

  const { scrollingDisabled } = props;

  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={<TopbarContainer rootClassName={css.topbarRoot} />}
        footer={<SectionFooter />}
      >
        <SectionHero />
        <SectionMatched />
        <SectionSlider />
        <SectionLocation />
        <SectionFaq />
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
