import React from 'react';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import { FieldTextInput, Form, H2, NamedLink } from '../../../../components';
import { pushToPath } from '../../../../util/urlHelpers';
import { Form as FinalForm } from 'react-final-form';
// import arrayMutators from 'final-form-arrays';
import dynamic from 'next/dynamic';


import hero from './img/heroBackground.webp';
import botox from './img/botoxInjections.jpg'
import lipfiller from './img/lipFillers.jpg'
import hair from './img/hair.jpg'
import eyelashes from './img/eyelashExtension.jpg'
import hovered from './img/hovered.svg'
import add from './img/add-icon.svg'
import css from './SectionHero.module.css';


const Swiper =
  typeof window !== 'undefined'
    ? require('swiper/react').Swiper
    : dynamic(() => import('swiper/react').then(mod => mod.Swiper), {
      ssr: false,
    });

const SwiperSlide =
  typeof window !== 'undefined'
    ? require('swiper/react').SwiperSlide
    : dynamic(() => import('swiper/react').then(mod => mod.SwiperSlide), {
      ssr: false,
    });

const SectionHero = props => {
  const intl = useIntl();

  return (
    <div className={css.root}>
      <div className={css.backgroundImageWrapper}>
        <img
          className={css.backgroundImage}
          src={hero}
        />
      </div>
      <div className={css.sectionContent}>
        <div className={css.infoContainer}>
          <H2 className={css.title}>
            <FormattedMessage
              id="SectionHero.titlePart1"
            />
            <br />
            <span className={css.customGradient}>
              <FormattedMessage
                id="SectionHero.titlePart2"
              />
            </span>
          </H2>
          <p className={css.description}>
            <FormattedMessage
              id="SectionHero.description"
              values={{ lineBreak: <br /> }}
            />
          </p>
          <p className={css.descriptionMobile}>
            <FormattedMessage
              id="SectionHero.descriptionMobile"
            />
          </p>
          <div className={css.searchContainer}>
            <FinalForm
              // mutators={{ ...arrayMutators }}
              onSubmit={(values) => {
                const url = values.search ? `/s?keywords=${values.search}` : `/s`;
                pushToPath(url);
              }}
              render={formRenderProps => {
                const {
                  handleSubmit,
                  values,
                } = formRenderProps;

                return (
                  <Form onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit();
                  }} className={css.searchForm}>
                    <div className={css.formRoot}>
                      <FieldTextInput
                        id="search"
                        name="search"
                        className={css.search}
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'SectionHero.searchPlaceholder',
                        })} />

                      <a
                        className={css.ctaButton}
                        onClick={() => {
                          pushToPath(
                            values.search ? `/s?keywords=${values.search}` : `/s`
                          );
                        }}
                      >
                        <FormattedMessage id="SectionHero.ctaButton" />
                      </a>
                    </div>
                  </Form>
                )
              }}
            />
          </div>
          <div className={css.cards}>
            {[
              { src: botox, labelId: 'SectionHero.botox', param: 'botox' },
              { src: lipfiller, labelId: 'SectionHero.lipFiller', marginTop: 32, param: 'lip filler' },
              { src: hair, labelId: 'SectionHero.hair', marginTop: 64, param: 'hair' },
              { src: eyelashes, labelId: 'SectionHero.eyelashes', param: "eyelashes" },
            ].map((card, index) => (
              <div
                key={index}
                className={css.card}
                style={{ marginTop: card.marginTop || 0 }}
                onClick={() => {
                  pushToPath(`/s?keywords=${card.param}`);
                }}
              >
                <img className={css.hoveredImage} src={hovered} />
                <div className={css.cardOverlay} />
                <img className={css.cardImage} src={card.src} />
                <h2 className={css.label}>
                  <FormattedMessage id={card.labelId} />
                </h2>
              </div>
            ))}
          </div>

          <div className={css.cardsSliderMobile}>
            <Swiper
              className={css.swiper}
              loop={true}
              grabCursor={true}
              slidesPerView={1.5}
              spaceBetween={24}
            >
              {[
                { src: botox, labelId: 'SectionHero.botox', param: 'botox' },
                { src: lipfiller, labelId: 'SectionHero.lipFiller', marginTop: 32, param: 'lip filler' },
                { src: hair, labelId: 'SectionHero.hair', marginTop: 64, param: 'hair' },
                { src: eyelashes, labelId: 'SectionHero.eyelashes', marginTop: 32, param: "eyelashes" },
              ].map((card, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => {
                    pushToPath(`/s?keywords=${card.param}`);
                  }}
                >
                  <div
                    className={css.card}
                    style={{ marginTop: card.marginTop || 0 }}
                  >
                    <img className={css.hoveredImage} src={hovered} />
                    <div className={css.cardOverlay} />
                    <img className={css.cardImage} src={card.src} />
                    <h2 className={css.label}>
                      <FormattedMessage id={card.labelId} />
                    </h2>
                  </div>
                </SwiperSlide>
              ))}


            </Swiper>


          </div>

          <NamedLink
            className={css.explore}
            name="SearchPage"
          >
            <div className={css.roundContainer}>
              <img src={add} />
            </div>

            <FormattedMessage id="SectionHero.exploreButton" />
          </NamedLink>

        </div>
      </div>
    </div >
  );
};

export default SectionHero;
