import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2 } from '../../../../components';
import { pushToPath } from '../../../../util/urlHelpers';

import css from './SectionLocation.module.css';

import ManchesterImage from './img/manchester.webp';
import ManchesterBorderImage from './img/manchester-border.webp';
import ArrowManchester from './img/arrow_manchester.svg';

import LondonImage from './img/london.webp';
import LondonBorderImage from './img/london-border.webp';
import ArrowLondon from './img/arrow_london.svg';

import LiverpoolImage from './img/liverpool.webp';
import LiverpoolBorderImage from './img/liverpool-border.webp';
import ArrowLiverpool from './img/arrow_liverpool.svg';

const SectionLocation = (props) => {
    return (
        <div className={css.root}>
            <span className={css.tag}>
                <FormattedMessage id="SectionLocation.tag" />
            </span>
            <H2 className={css.title}>
                <FormattedMessage id="SectionLocation.titlePart1" />
                <br />
                <span className={css.customGradient}>
                    <FormattedMessage id="SectionLocation.titlePart2" />
                </span>
            </H2>
            <p className={css.description}>
                <FormattedMessage
                    id="SectionLocation.description"
                    values={{ lineBreak: <br /> }}
                />
            </p>

            <div className={css.imageContainer}>
                <div
                    className={`${css.image} ${css.image1}`}
                    onClick={() => {
                        pushToPath("s?address=Manchester%2C%20Greater%20Manchester%2C%20England%2C%20United%20Kingdom&bounds=53.586199%2C-2.087832%2C53.348598%2C-2.52355");
                    }}
                >
                    <img className={css.hoveredArrow} src={ArrowManchester} alt="Manchester" />
                    <p className={css.text1}>Manchester</p>
                    <img
                        className={css.hovered}
                        src={ManchesterImage}
                        alt="Manchester"
                    />
                    <img
                        className={css.original}
                        src={ManchesterBorderImage}
                        alt="Manchester"
                    />
                </div>

                <div
                    className={`${css.image} ${css.image2}`}
                    onClick={() => {
                        pushToPath("s?address=Liverpool%2C%20Merseyside%2C%20England%2C%20United%20Kingdom&bounds=53.6016216%2C-2.7702697%2C53.309759%2C-3.1324014");
                    }}
                >
                    <img className={css.hoveredArrow} src={ArrowLiverpool} alt="Liverpool" />
                    <p className={css.text2}>Liverpool</p>
                    <img
                        className={css.hovered}
                        src={LiverpoolImage}
                        alt="Liverpool"
                    />
                    <img
                        className={css.original}
                        src={LiverpoolBorderImage}
                        alt="Liverpool"
                    />
                </div>

                <div
                    className={`${css.image} ${css.image3}`}
                    onClick={() => {
                        pushToPath("s?address=London%2C%20Greater%20London%2C%20England%2C%20United%20Kingdom&bounds=51.669993%2C0.153177%2C51.384527%2C-0.351708");
                    }}
                >
                    <img className={css.hoveredArrow} src={ArrowLondon} alt="London" />
                    <p className={css.text3}>London</p>
                    <img
                        className={css.hovered}
                        src={LondonImage}
                        alt="London"
                    />
                    <img
                        className={css.original}
                        src={LondonBorderImage}
                        alt="London"
                    />
                </div>
            </div>
        </div>
    );
};

export default SectionLocation;
